function getId(url) {
  if (url.includes("youtube")) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11
      ? "//www.youtube.com/embed/" + match[2] + "?html5=1"
      : null;
  } else if (url.includes("vimeo")) {
    const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    const match = url.match(vimeoRegex);

    return "//player.vimeo.com/video/" + match[1];
  }
}

function getThumb(url) {
  if (url.includes("youtube")) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11
      ? "https://img.youtube.com/vi/" + match[2] + "/hqdefault.jpg"
      : null;
  } else if (url.includes("vimeo")) {
    const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    const match = url.match(vimeoRegex);

    return "https://vumbnail.com/" + match[1] + ".jpg";
  }
}

export { getThumb, getId };
