import React from "react";
import PropTypes from "prop-types";
import { getThumb, getId } from "./getId";

function Video(props) {
  if (!props.src) {
    return <span />;
  } else {
    return (
    <div class="my-5 ratio ratio-16x9">
      <iframe
        src={getId(props.src)}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      </div>
    );
  }
}

export default Video;